<template>
    <div class="component-container white">
        <div :id="cmsBlock.anchorTag" class="startup-slider-wrapper dark">
            <div class="startup-slider" ref="slider">
                <div class="slide" v-for="(slide, index) in cmsBlock.startupSlides" :key="slide.id">
                    <div class="slide-image-container">
                        <picture>
                            <template v-for="[format, set] of Object.entries(srcsets[index])">
                                <source v-if="format !== originalFormats[index]" :srcset="set" sizes="(max-width: 1200px) 90vw, 50vw"
                                    :type="'image/' + format.slice(1)">
                            </template>
                            <img class="slide-image" loading="lazy" decoding="async" :srcset="srcsets[index][originalFormats[index]]" @load="onImgLoad"
                                :type="'image/' + originalFormats[index].slice(1)" sizes="(max-width: 1200px) 90vw, 50vw"
                                :alt="slide.image.data.attributes.alternativeText">
                        </picture>
                        <Copyright v-if="hasCopyright(slide) && isMobile()" :text="slide.copyright" />
                    </div>
                    <div class="slide-details-wrapper">
                        <div class="slide-details-content">
                            <div class="logo">
                                <img loading="lazy" decoding="async" :src="getImageUrl(slide.logo.data.attributes.url)"
                                    :alt="slide.logo.data.attributes.alternativeText" />
                            </div>
                            <div class="slide-text" v-if="localizeAlt(slide, 'text')"
                                v-html="md(localizeAlt(slide, 'text'))"></div>
                        </div>
                    </div>
                    <Copyright v-if="hasCopyright(slide) && !isMobile()" :text="slide.copyright" />
                </div>
            </div>
            <div class="slider-ui">
                <div class="slider-arrows">
                    <div class="slider-prev" v-on:click="prevSlide">
                        <img src="@/assets/arrow_left.svg" width="21px" height="40px" alt="Previous Slide" />
                    </div>
                    <div class="slider-next" v-on:click="nextSlide">
                        <img src="@/assets/arrow_right.svg" width="21px" height="40px" alt="Next Slide" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from 'flickity'
import Copyright from '@/components/PageBuilder/Elements/Copyright'
import { isMobile } from '@/helper'

export default {
    name: 'StartupSlider',

    props: [
        'cmsBlock'
    ],

    components: {
        Copyright
    },

    data() {
        return {
            slider: null
        }
    },

    computed: {
        srcsets() {
            let srcsets = []
            for (const slide of this.cmsBlock.startupSlides) {
                srcsets.push(this.getImageSrcsets(slide.image.data.attributes))
            }
            return srcsets
        },

        hasWebp() {
            let hasWebps = []
            for (const srcset of this.srcsets) {
                hasWebps.push(srcset['.webp'] ? true : false)
            }
            return hasWebps
        },

        originalFormats() {
            let originalFormats = []
            for (const srcset of this.srcsets) {
                for (const [format, _] of Object.entries(srcset)) {
                    if (format !== '.webp') {
                        originalFormats.push(format)
                    }
                }
            }

            return originalFormats
        },
    },

    mounted() {
        var self = this
        this.$nextTick(() => {
            self.slider = new Flickity(self.$refs.slider, {
                prevNextButtons: false,
                pageDots: true,
                autoPlay: 7000, // 7000
                wrapAround: true,
                pauseAutoPlayOnHover: false,
                imagesLoaded: true
            })
        })
    },

    methods: {
        onImgLoad() {
            this.slider.resize()
        },

        hasCopyright(slide) {
            return slide.copyright ? slide.copyright.length > 0 : false
        },

        isMobile: isMobile,

        prevSlide() {
            this.slider.previous()
        },

        nextSlide() {
            this.slider.next()
        },

        slideActive(slide) {
            if (this.slider) {
                return {
                    active: this.slider.selectedIndex == slide - 1
                }
            }
        },

        selectSlide(slide) {
            this.slider.select(slide - 1)
        }

    }
}
</script>

<style lang="scss" scoped>
.component-container {
    .startup-slider-wrapper {
        position: relative;
        max-width: $container-width;
        margin: 0 auto;
        height: unset;
        aspect-ratio: 16/9;
        overflow: hidden;

        @include breakpoint('mobile') {
            aspect-ratio: unset;
            height: 625px;
        }

        .startup-slider {
            overflow: hidden;
            position: relative;
            height: 100%;

            ::v-deep .flickity-viewport {
                height: 100% !important;

                .flickity-slider {
                    height: 100% !important;
                }


                .slide {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    .slide-image-container {
                        position: relative;
                        height: 100%; //900px; //1440px
                        width: 100%;

                        @include breakpoint('mobile') {
                            height: unset;
                            aspect-ratio: 16/9;
                        }

                        /*@include breakpoint('max-width') {
                        height: 900px;
                    }

                    @include breakpoint('tablet') {
                        height: 711px;
                    }

                    @include breakpoint('tablet-xs') {
                        height: 533px;
                    }

                    @include breakpoint('mobile') {
                        height: 430px;
                    }

                    @include breakpoint('mobile-xs') {
                        height: 242px;
                    }*/

                        .slide-image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }



                    .slide-details-wrapper {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        background: rgba(0, 0, 0, 0.5);
                        padding: getSpacing('padding-default-horizontal', 'mobile');
                        padding-bottom: calc(getSpacing('padding-default-horizontal', 'mobile') + 34px);
                        /*
                    @include breakpoint('tablet') {
                        padding: 30px;
                        padding-bottom: 60px;
                    }*/

                        @include breakpoint('mobile') {
                            position: relative;
                            background: $timberGreen;
                        }

                        .slide-details-content {
                            width: 100%;
                            margin: 0 auto;
                            display: flex;

                            @include breakpoint('mobile') {
                                flex-direction: column;
                                width: 100%;
                            }

                            .logo {
                                display: flex;
                                align-items: center;
                                height: 100px;
                                width: 450px;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    margin: auto;
                                    display: block;
                                    object-fit: contain;
                                    filter: brightness(0) grayscale(1) invert(1);
                                }

                                @include breakpoint('mobile') {
                                    justify-content: center;
                                    height: 75px;
                                    width: 50%;
                                    margin: 0 auto;
                                    margin-bottom: 20px;
                                }

                                .startup-title {
                                    font-size: getFontSize('sliderLogoText', 'desktop');
                                    font-weight: getFontWeight('sliderLogoText');
                                    font-style: getFontStyle('sliderLogoText');
                                    font-family: getFontFamily('sliderLogoText');
                                    margin-left: 20px;

                                    @include breakpoint('tablet') {
                                        font-size: getFontSize('sliderLogoText', 'tablet');
                                    }

                                    @include breakpoint('mobile') {
                                        font-size: getFontSize('sliderLogoText', 'mobile');
                                    }

                                    .title {
                                        font-size: getFontSize('sliderLogoTextBold', 'desktop');
                                        font-weight: getFontWeight('sliderLogoTextBold');
                                        font-style: getFontStyle('sliderLogoTextBold');
                                        font-family: getFontFamily('sliderLogoTextBold');

                                        @include breakpoint('tablet') {
                                            font-size: getFontSize('sliderLogoTextBold', 'tablet');
                                        }

                                        @include breakpoint('mobile') {
                                            font-size: getFontSize('sliderLogoTextBold', 'mobile');
                                        }
                                    }
                                }
                            }

                            .slide-text {
                                margin-left: 50px;

                                @include breakpoint('mobile') {
                                    margin-left: 0;
                                }

                                p {
                                    font-size: getFontSize('smallText', 'desktop');
                                    font-family: getFontFamily('smallText');
                                    text-align: start;
                                    margin: 5px 0px;

                                    @include breakpoint('tablet') {
                                        font-size: getFontSize('smallText', 'tablet');
                                    }

                                    @include breakpoint('mobile') {
                                        font-size: getFontSize('smallText', 'mobile');
                                    }
                                }

                                strong {
                                    font-size: getFontSize('smallText', 'desktop');

                                    @include breakpoint('tablet') {
                                        font-size: getFontSize('smallText', 'tablet');
                                    }

                                    @include breakpoint('mobile') {
                                        font-size: getFontSize('smallText', 'mobile');
                                    }
                                }

                                a {
                                    font-size: getFontSize('smallText', 'desktop');

                                    @include breakpoint('tablet') {
                                        font-size: getFontSize('smallText', 'tablet');
                                    }

                                    @include breakpoint('mobile') {
                                        font-size: getFontSize('smallText', 'mobile');
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}
</style>
